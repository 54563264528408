export type SiteConfig = typeof siteConfig;

export const siteConfig = {
  name: "Next.js + NextUI",
  description: "Make beautiful websites regardless of your design experience.",
  navItems: [
    {
      label: "header-aboutLink",
      href: "/about",
      ariraLabel: "bounty-list-view-link",
    },
    {
      label: "header-contactLink",
      href: "/#contact",
      ariraLabel: "community-view-link",
    },
    {
      label: "header-blogLink",
      href: "/blog",
      ariraLabel: "blog-link",
    },
    {
      label: "header-jobsLink",
      href: "/user/jobs",
      ariraLabel: "jobs-link",
    },
    {
      label: "header-loginLink",
      href: "/login",
      ariraLabel: "jobs-link",
    },
    {
      label: "header-bountiesLink",
      href: "/user/bounties",
      ariraLabel: "bounties-link",
    },
  ],
};

export const userMenuItems: any = [
  {
    key: "home",
    href: "user/",
    title: "profile",
    icon: "solar:home-2-linear",
  },
  {
    key: "jobs",
    href: "user/jobs",
    title: "jobs",
    icon: "solar:folder-2-line-duotone",
  },
  {
    key: "applications",
    href: "user/applications",
    title: "applications",
    icon: "solar:file-check-linear",
  },
  {
    key: "bounties",
    href: "user/bounties",
    title: "bounties",
    icon: "solar:money-bag-line-duotone",
  },
];
