import(/* webpackMode: "eager" */ "/vercel/path0/components/Footer/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/vercel/path0/components/Navbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.25.0_next@15.0.3_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.6__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.25.0_next@15.0.3_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.6__react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.25.0_next@15.0.3_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.6__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.3_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.6/node_modules/next/dist/client/script.js");
